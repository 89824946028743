import { animated, useSpring } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

type IProps = {
  children?: React.ReactNode;
  className?: string;
  delay?: boolean;
  zoom: number;
  triggerOnce?: boolean;
};

const ZoomIn = ({ children, className, delay, zoom, triggerOnce }: IProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
    triggerOnce: triggerOnce,
  });
  const zoomIn = useSpring({
    from: { transform: `scale(${zoom})` },
    to: {
      transform: inView ? 'scale(1)' : `scale(${zoom})`,
    },
    delay: delay ? 200 : 0,
  });
  // use a custom tag for the animated dom
  return (
    <animated.div ref={ref} className={className} style={zoomIn}>
      {children}
    </animated.div>
  );
};

export default ZoomIn;
